<template>
  <b-container fluid>
      <b-row>
          <b-col sm="12">
              <iq-card>
                  <template v-slot:headerTitle>
                      <div class="text-center">
                          <h5 class="card-title"> {{ $t('externalTraining.course_evaluation') }}</h5>
                      </div>
                  </template>
                  <template v-slot:headerAction>
                    <router-link class="btn btn_add_new" :to="`training-course-evaluation`">
                      {{ $t('externalTraining.course_evaluation') }} {{ $t('globalTrans.list') }}
                    </router-link>
                  </template>
                  <template v-slot:body>
                    <b-overlay :show="isLoading">
                      <b-row class="mb-3 ml-0 mr-2">
                        <b-col sm="12"><h5>{{ $t('externalTraining.course_title') + ' : ' +  ($i18n.locale === 'bn' ? circularData.training_title_bn : circularData.training_title_en) }}</h5></b-col>
                        <b-col sm="12"><h5>{{ $t('externalTraining.course_duration') + ' : ' + ($i18n.locale === 'bn' ? date_bn : date) }}</h5></b-col>
                      </b-row>
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form class="row" id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                          <fieldset class="p-2 w-100">
                            <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.common_assessment_question_paper')}}</legend>
                            <b-row class="mb-3 ml-2 mr-2">
                              <b-col sm="12" class="mb-3">
                                <h5 class="text-left">{{ $t('externalTraining.question_paper_head') }}</h5>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Name" vid='question_1' rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_1"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '১। ' : '1। ' }} {{ $t('externalTraining.question_1')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-radio-group
                                      v-model="formData.question_1"
                                      :options="question1Option"
                                      class="mb-3"
                                      name="question_1"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">Please select one</b-form-invalid-feedback>
                                      </b-form-radio-group>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Name" vid='question_2' rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_2"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '২। ' : '2। ' }}  {{ $t('externalTraining.question_2')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-radio-group
                                      v-model="formData.question_2"
                                      :options="question2Option"
                                      class="mb-3"
                                      name="question_2"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">Please select one</b-form-invalid-feedback>
                                      </b-form-radio-group>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 3" vid='question_3' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_3"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '৩। ' : '3। ' }} {{ $t('externalTraining.question_3')}} <span class="text-danger">*</span>
                                    </template>
                                    <vue-editor
                                      id="personal_info"
                                      v-model="formData.question_3"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></vue-editor>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 4" vid='question_4' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_4"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '৪। ' : '4। ' }} {{ $t('externalTraining.question_4')}} <span class="text-danger">*</span>
                                    </template>
                                    <vue-editor
                                      id="question_4"
                                      v-model="formData.question_4"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></vue-editor>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 5" vid='question_5' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_5"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '৫। ' : '5। ' }}  {{ $t('externalTraining.question_5')}} <span class="text-danger">*</span>
                                    </template>
                                    <vue-editor
                                      id="question_5"
                                      v-model="formData.question_5"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></vue-editor>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Name" vid='question_6' rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_6"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '৬। ' : '6। ' }}  {{ $t('externalTraining.question_6')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-radio-group
                                      v-model="formData.question_6"
                                      :options="question6Option"
                                      class="mb-3"
                                      name="question_6"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">Please select one</b-form-invalid-feedback>
                                      </b-form-radio-group>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Name" vid='question_7' rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_7"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '৭। ' : '7। ' }}  {{ $t('externalTraining.question_7')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-radio-group
                                      v-model="formData.question_7"
                                      :options="question7Option"
                                      class="mb-3"
                                      name="question_7"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">Please select one</b-form-invalid-feedback>
                                      </b-form-radio-group>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 8" vid='question_8' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_8"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '৮। ' : '8। ' }}  {{ $t('externalTraining.question_8')}} <span class="text-danger">*</span>
                                    </template>
                                      <b-form-checkbox-group
                                        v-model="formData.question_8"
                                        :options="question8Option"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-checkbox-group>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 9" vid='question_9' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_9"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '৯। ' : '9। ' }} {{ $t('externalTraining.question_9')}} <span class="text-danger">*</span>
                                    </template>
                                      <b-form-checkbox-group
                                        v-model="formData.question_9"
                                        :options="question9Option"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-checkbox-group>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Name" vid='question_10' rules="required|min_value:1">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_10"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '১০। ' : '10। ' }}  {{ $t('externalTraining.question_10')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-radio-group
                                      v-model="formData.question_10"
                                      :options="question10Option"
                                      class="mb-3"
                                      name="question_10"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                        <b-form-invalid-feedback :state="errors[0] ? false : (valid ? true : null)">Please select one</b-form-invalid-feedback>
                                      </b-form-radio-group>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 11" vid='question_11' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_11"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '১১। ' : '11। ' }} {{ $t('externalTraining.question_11')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                      id="question_11"
                                      rows="2"
                                      v-model="formData.question_11"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 12" vid='question_12' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_12"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '১২। ' : '12। ' }}  {{ $t('externalTraining.question_12')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                      id="question_12"
                                      rows="2"
                                      v-model="formData.question_12"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 13" vid='question_13' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_13"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '১৩। ' : '13। ' }} {{ $t('externalTraining.question_13')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                      id="question_13"
                                      rows="2"
                                      v-model="formData.question_13"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <!-- <pre>{{ formData.question_14 }}</pre> -->
                              <b-col sm="12">
                                <slot>{{ $i18n.locale === 'bn' ? '১৪। ' : '14। ' }}  {{ $t('externalTraining.question_14') }}</slot>
                                <table class="table" style="width:100%" border="1">
                                  <thead class="thead">
                                    <tr>
                                      <th class="text-center" style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                                      <th class="text-center" style="width:20%">{{$t('externalTraining.question_14_1')}}</th>
                                      <th class="text-center" style="width:14%">{{$t('externalTraining.question_14_2')}}</th>
                                      <th class="text-center" style="width:14%">{{$t('externalTraining.question_14_3')}}</th>
                                      <th class="text-center" style="width:14%">{{$t('externalTraining.question_14_4')}}</th>
                                      <th class="text-center" style="width:14%">{{$t('externalTraining.question_14_5')}}</th>
                                      <th class="text-center" style="width:14%">{{$t('externalTraining.question_14_6')}}</th>
                                    </tr>
                                  </thead>
                                  <tr v-for="(data, index) in formData.question_14" :key="index">
                                    <td>{{ $n(index + 1) }}</td>
                                    <td>{{ $i18n.locale === 'bn' ? data.name_bn : data.name }}</td>
                                    <td class="text-center" :key="index1" v-for="(item,index1) in data.details">
                                      <b-form-radio inline v-model="data.value" :name="index + 'dd'" :key="index" :value="item.value"></b-form-radio>
                                      <!-- <b-form-radio :name="'dd'" v-model="question_15" size="sm">Small</b-form-radio> -->
                                    </td>
                                  </tr>
                                </table>
                              </b-col>
                              <b-col sm="12">
                                <ValidationProvider name="Question 15" vid='question_15' rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="question_15"
                                    slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $i18n.locale === 'bn' ? '১৫। ' : '15। ' }}  {{ $t('externalTraining.question_15')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                      id="question_15"
                                      rows="3"
                                      v-model="formData.question_15"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                            <div class="text-right">
                              <b-button type="submit" variant="primary" class="mr-2">
                                <!-- <span><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span> -->
                                <span>{{ saveBtnName }}</span>
                              </b-button>
                              <router-link to="training-course-evaluation" class="btn btn-danger mr-3">{{ $t('globalTrans.cancel') }}</router-link>
                            </div>
                          </fieldset>
                        </b-form>
                      </ValidationObserver>
                    </b-overlay>
                  </template>
                <!-- <pre>{{ formData }}</pre> -->
              </iq-card>
          </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularData, traineeCourseEvaluationStore } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { VueEditor } from 'vue2-editor'

export default {
    props: ['id'],
    mixins: [ModalBaseMasterList],
    components: {
      ValidationObserver,
      ValidationProvider,
      VueEditor
    },
    data () {
        return {
          saveBtnName: this.$t('globalTrans.save'),
          isLoading: false,
          formData: {
            circular_id: 0,
            circular_memo_no: '',
            question_1: 0,
            question_2: 0,
            question_3: '',
            question_4: '',
            question_5: '',
            question_6: 0,
            question_7: 0,
            question_8: [],
            question_9: [],
            question_10: 0,
            question_11: '',
            question_12: '',
            question_13: '',
            question_14: [
              {
                name: 'Uninterrupted training facility',
                name_bn: 'নিরবচ্ছিন্ন প্রশিক্ষণ সুবিধা',
                value: 0,
                details: [
                  { value: 100 },
                  { value: 90 },
                  { value: 80 },
                  { value: 70 },
                  { value: 60 }
                ]
              },
              {
                name: 'Cleanliness',
                name_bn: 'পরিচ্ছন্নতা',
                value: 0,
                details: [
                  { value: 100 },
                  { value: 90 },
                  { value: 80 },
                  { value: 70 },
                  { value: 60 }
                ]
              },
              {
                name: 'Healthy food management',
                name_bn: 'সুষ্ঠু খাবার ব্যবস্থাপনা',
                value: 0,
                details: [
                  { value: 100 },
                  { value: 90 },
                  { value: 80 },
                  { value: 70 },
                  { value: 60 }
                ]
              },
              {
                name: 'Library facilities',
                name_bn: 'লাইব্রেরী সুবিধা',
                value: 0,
                details: [
                  { value: 100 },
                  { value: 90 },
                  { value: 80 },
                  { value: 70 },
                  { value: 60 }
                ]
              },
              {
                name: 'Audiovisual facilities',
                name_bn: 'অডিওভিজ্যুয়াল সুবিধা',
                value: 0,
                details: [
                  { value: 100 },
                  { value: 90 },
                  { value: 80 },
                  { value: 70 },
                  { value: 60 }
                ]
              }
            ],
            question_15: ''
          },
          date: '',
          date_bn: '',
          circularData: {},
          allData: []
        }
    },
    computed: {
      question1Option () {
        const question1Option = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Enough' : 'পর্যাপ্ত', text_en: 'Enough', text_bn: 'পর্যাপ্ত' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Roughly' : 'মোটামুটি', text_en: 'Roughly', text_bn: 'মোটামুটি' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Inadequate' : 'অপর্যাপ্ত', text_en: 'Inadequate', text_bn: 'অপর্যাপ্ত' }
        ]
        return question1Option
      },
      question2Option () {
        const question2Option = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Very Long' : 'খুবই দীর্ঘ', text_en: 'Very Long', text_bn: 'খুবই দীর্ঘ' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Enough' : 'পর্যাপ্ত', text_en: 'Enough', text_bn: 'পর্যাপ্ত' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Roughly' : 'মোটামুটি', text_en: 'Roughly', text_bn: 'মোটামুটি' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Very Short' : 'খুবই সংক্ষিপ্ত', text_en: 'Very Short', text_bn: 'খুবই সংক্ষিপ্ত' }
        ]
        return question2Option
      },
      question6Option () {
        const question6Option = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Very Good' : 'খুবই ভাল', text_en: 'Very Good', text_bn: 'খুবই ভাল' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Good' : 'ভাল', text_en: 'Good', text_bn: 'ভাল' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Roughly' : 'মোটামুটি', text_en: 'Roughly', text_bn: 'মোটামুটি' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Not Good' : 'ভাল নয়', text_en: 'Not Good', text_bn: 'ভাল নয়' }
        ]
        return question6Option
      },
      question7Option () {
        const question7Option = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Very Relevant' : 'খুবই প্রাসংগিক', text_en: 'Very Relevant', text_bn: 'খুবই প্রাসংগিক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Fairly Relevant' : 'মোটামুটি প্রাসংগিক', text_en: 'Fairly Relevant', text_bn: 'মোটামুটি প্রাসংগিক' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Relevant in some cases' : 'কোন কোন ক্ষেত্রে প্রাসংগিক', text_en: 'Relevant in some cases', text_bn: 'কোন কোন ক্ষেত্রে প্রাসংগিক' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Not relevant at all' : 'একেবারেই প্রাসংগিক না', text_en: 'Not relevant at all', text_bn: 'একেবারেই প্রাসংগিক না' }
        ]
        return question7Option
      },
      question8Option () {
        const question8Option = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Being aware of knowledge and information' : 'জ্ঞান ও তথ্য সম্পর্কে অবগত হওয়া', text_en: 'Being aware of knowledge and information', text_bn: 'জ্ঞান ও তথ্য সম্পর্কে অবগত হওয়া' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Know the special abilities and methods to work' : 'কাজ করার জন্য বিশেষ ক্ষমতা ও পদ্ধতি জানা', text_en: 'Know the special abilities and methods to work', text_bn: 'কাজ করার জন্য বিশেষ ক্ষমতা ও পদ্ধতি জানা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'To acquire the ability to function properly' : 'সুষ্ঠুভাবে কাজ করার ক্ষমতা অর্জন করা', text_en: 'To acquire the ability to function properly', text_bn: 'সুষ্ঠুভাবে কাজ করার ক্ষমতা অর্জন করা' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Confidence levels have increased' : 'কনফিডেন্সের মাত্রা বেড়েছে', text_en: 'Confidence levels have increased', text_bn: 'কনফিডেন্সের মাত্রা বেড়েছে' }
        ]
        return question8Option
      },
      question9Option () {
        const question9Option = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Classroom Lectures' : 'ক্লাশরুম বক্তৃতা', text_en: 'Classroom Lectures', text_bn: 'ক্লাশরুম বক্তৃতা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Discussion and practice' : 'আলোচনা ও অনুশীলন', text_en: 'Discussion and practice', text_bn: 'আলোচনা ও অনুশীলন' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'group discussion' : 'দলগত আলোচনা', text_en: 'group discussion', text_bn: 'দলগত আলোচনা' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Group problem solving' : 'দলগত সমস্যা সমাধান', text_en: 'Group problem solving', text_bn: 'দলগত সমস্যা সমাধান' },
          { value: 5, text: this.$i18n.locale === 'en' ? 'Field visit' : 'মাঠ পরিদর্শন', text_en: 'Field visit', text_bn: 'মাঠ পরিদর্শন' },
          { value: 6, text: this.$i18n.locale === 'en' ? 'Film show' : 'ফিল্ম শো', text_en: 'Film show', text_bn: 'ফিল্ম শো' },
          { value: 7, text: this.$i18n.locale === 'en' ? 'Other' : 'অন্যান্য', text_en: 'Other', text_bn: 'অন্যান্য' }
        ]
        return question9Option
      },
      question10Option () {
        const question10Option = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Very Satisfactory' : 'খুবই সন্তোষজনক', text_en: 'Very Satisfactory', text_bn: 'খুবই সন্তোষজনক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Satisfactory' : 'সন্তোষজনক', text_en: 'Satisfactory', text_bn: 'সন্তোষজনক' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Not Satisfactory' : 'সন্তোষজনক নয়', text_en: 'Not Satisfactory', text_bn: 'সন্তোষজনক নয়' }
        ]
        return question10Option
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    async created () {
      this.getCircularData()
    },
    mounted () {
        core.index()
    },
    methods: {
      dataChange () {
          // this.loadData()
      },
      async searchData () {
          // this.loadData()
      },
      getCircularData () {
        this.isLoading = true
        const params = {
          circular_memo_no: this.$route.query.circularMemoNo
        }
        RestApi.getData(trainingElearningServiceBaseUrl, circularData, params).then(response => {
          if (response.success) {
            this.circularData = response.data
            this.formData.circular_id = response.data.id
            this.formData.circular_memo_no = response.data.circular_memo_no
            const startDate = response.data.training_start_date.split('-')
            const endDate = response.data.training_end_date.split('-')
            let startMonthId = ''
            let endMonthId = ''
            startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
            endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
            // if (startDate[1] === endDate[1]) {
            //   this.date = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ',' + this.$n(startDate[0], { useGrouping: false })
            // } else {
              this.date = startDate[2] + ' ' + startMonthId.text_en + ' ' + '-' + ' ' + endDate[2] + ' ' + endMonthId.text_en + ' ' + startDate[0]
              this.date_bn = this.convertToBanglaNumbers(startDate[2]) + ' ' + startMonthId.text_bn + ' ' + '-' + ' ' + this.convertToBanglaNumbers(endDate[2]) + ' ' + endMonthId.text_bn + ' ' + this.convertToBanglaNumbers(startDate[0], { useGrouping: false })
            // }
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        })
      },
      convertToBanglaNumbers (input) {
        function toBanglaNumber (match) {
          const numberMap = {
            0: '০',
            1: '১',
            2: '২',
            3: '৩',
            4: '৪',
            5: '৫',
            6: '৬',
            7: '৭',
            8: '৮',
            9: '৯'
          }
          return match.split('').map(char => numberMap[char] || char).join('')
        }
        return input.replace(/[0-9]/g, toBanglaNumber)
      },
      async createData () {
        this.isLoading = true
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }

        result = await RestApi.postData(trainingElearningServiceBaseUrl, traineeCourseEvaluationStore, this.formData)

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$router.push({
            path: '/trainee-trainer-panel/training-course-evaluation'
          })
          this.formData = {}
          this.$refs.form.reset()
          this.showMessage = true
          this.isLoading = false
        } else {
          this.$refs.form.setErrors(result.errors)
          this.isLoading = false
        }
        this.loading = false
        this.isLoading = false
      }
      // pdfExport () {
      //   this.$refs.details.pdfExport()
      // }
    }
}
</script>
